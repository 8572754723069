import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import LeftRazzleSVG from '../../../images/cta-left-razzle.svg';
import LeftSwooshSVG from '../../../images/cta-left-swoosh.svg';
import RightDazzleSVG from '../../../images/cta-right-dazzle.svg';
import RightSwooshSVG from '../../../images/cta-right-swoosh.svg';
import GreenLineSVG from '../../../images/green-line.svg';
import { colors } from '../../../modules/colors';
import { FullWidthField } from '../../full-width-field';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../../typography';
import { HorizontalColorSplitSVG } from '../color-split-svg';
import { CTAButton } from '../cta-button';
import { Guarantees } from '../guarantees';

const CTABackgroundStyle = createOwnUpStyle({
  backgroundImage: `url(${LeftSwooshSVG}), url(${RightSwooshSVG})`,
  backgroundPosition: 'left center, 100% 75px',
  backgroundRepeat: 'no-repeat, no-repeat',
  variants: {
    mediumAndDown: {
      backgroundImage: `url(${RightSwooshSVG})`
    },
    medium: {
      backgroundPosition: '100% 75px'
    },
    small: {
      backgroundPosition: '100% 190px'
    },
    xsmall: {
      backgroundPosition: '100% 100%'
    }
  }
});

const CTABackground = createOwnUpComponent(OwnUpBox, CTABackgroundStyle);

const CTAContentStyle = createOwnUpStyle({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: 1280,
  margin: 'auto',
  padding: '0 60px 60px',
  variants: {
    smallAndDown: {
      padding: '0 35px 80px'
    }
  }
});

const CTAContentWrapper = createOwnUpComponent(OwnUpBox, CTAContentStyle);

const CTAHeaderBackgroundStyle = createOwnUpStyle({
  '::first-letter': {
    backgroundImage: `url(${LeftRazzleSVG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left -5px top',
    backgroundSize: 30,
    padding: '20px 0 0 20px'
  },
  variants: {
    large: {
      backgroundImage: `url(${RightDazzleSVG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right bottom',
      backgroundSize: 30
    }
  }
});

const CTAHeaderBackground = createOwnUpComponent(OwnUpBox, CTAHeaderBackgroundStyle);

const CTAHeaderStyle = createOwnUpStyle({
  fontSize: 40,
  letterSpacing: '.5px',
  margin: '15px 30px',
  variants: {
    smallAndDown: {
      fontSize: 33,
      margin: '15px 0'
    }
  }
});

const CTAHeader = createOwnUpComponent(ResponsiveSectionHeader, CTAHeaderStyle);

const GrayTextStyle = createOwnUpStyle({
  ...fonts.MONOSTEN_LIGHT,
  fontFamily: "'Monosten', 'Arial', 'sans-serif'",
  color: colors.PRIMARY,
  fontSize: 15,
  letterSpacing: '1px',
  paddingTop: 25
});

const GrayText = createOwnUpComponent(ResponsivePrimaryText, GrayTextStyle);

export const CTA = () => (
  <section aria-label="Get Started">
    <FullWidthField backgroundColor={colors.AQUA}>
      <CTABackground>
        <HorizontalColorSplitSVG
          topColor={colors.WHITE}
          bottomColor="transparent"
          SVG={GreenLineSVG}
          height={86}
        />
        <CTAContentWrapper>
          <GrayText>GET STARTED</GrayText>
          <CTAHeaderBackground>
            <CTAHeader>Ready to get your personalized rates?</CTAHeader>
          </CTAHeaderBackground>
          <CTAButton text="Build Your Profile In 4 Minutes" />
          <Guarantees />
        </CTAContentWrapper>
      </CTABackground>
    </FullWidthField>
  </section>
);
